import { render, staticRenderFns } from "./CookieBox.vue?vue&type=template&id=f382481a&scoped=true&"
import script from "./CookieBox.vue?vue&type=script&lang=js&"
export * from "./CookieBox.vue?vue&type=script&lang=js&"
import style0 from "./CookieBox.vue?vue&type=style&index=0&id=f382481a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f382481a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f382481a')) {
      api.createRecord('f382481a', component.options)
    } else {
      api.reload('f382481a', component.options)
    }
    module.hot.accept("./CookieBox.vue?vue&type=template&id=f382481a&scoped=true&", function () {
      api.rerender('f382481a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8fNvix2Dr52nw/JS/comp/CookieBox.vue"
export default component.exports